.forgot-section {
  height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;

  .forgot {
    color: black;
    text-align: center;

    .forgot-wrapper {
      max-width: 512px;
      width: 100%;
    }

    h2 {
      font-weight: 400;
      margin-bottom: 80px;

      span {
        color: green;
        font-weight: 700;
      }
    }

    .forgot-square {
      input {
        width: 50px;
        padding: 15px;
        text-align: center;
        margin-left: 5px;
      }
    }

    input {
      max-width: 400px;
      width: 100%;
      padding: 15px 10px;
      margin-bottom: 80px;
      border: 1px solid #999;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }

    .flex {
      .btn-login {
        color: white !important;
        padding: 10px 20px !important;
        background-color: crimson;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;

        &:nth-child(2) {
          background-color: green;
        }
      }
    }
  }
}

  @media only screen and (max-width: 500px) {
    .forgot-section {
      .forgot {
        .forgot-square {
          input {
            width: 40px;
          }
        }
        h2{
          font-size: 20px;
          margin-bottom: 40px;
        }
        input{
          margin-bottom: 40px;
        }
      }
    }
  }
