.mygroup__btn{
  margin-top: 30px;
  display: flex;
  justify-content: end;
  gap: 10px;
}

@media only screen and (max-width: 830px) {
  .tech-attendance{
    .mygroup__table-body--item{
      align-items: normal !important;

      & > div{
        &:nth-child(2){
          width: 70% !important;
        }
        &:nth-child(3){
          width: 30% !important;
        }
      }
    }
  }

  .tech-attendance-mobile{
    .mygroup__table-head{
      &:nth-child(3){
        padding: 3px 5px;
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  .tech-attendance{
    .mygroup__table-body--item{
      & > div{
        &:nth-child(2){
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: green;
          width: 100% !important; 
          max-width: 300px; /* istalgan o'lchamdagi miqdorni o'zgartiring */
        }
        &:nth-child(3){
          width: 120px;
        }
      }
    }
  }
}