.tech-homework-rating{
   .avg-point{
    width: 201px !important;
   //  border-left: 1px solid green !important;
    // align-self: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
   }
}