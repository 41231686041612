.mygroup-section {
  table {
    width: 100%;
    border-collapse: collapse;
    border-collapse: collapse;
  }

  .mygroup {
    padding-bottom: 50px;
  }

  .mobile {
    display: none;
  }

  @media only screen and (max-width: 830px) {
    .tablet {
      display: none;
    }
    .desktop {
      display: none;
    }

    .mobile {
      display: block;

      .mygroup__table-head {
        display: flex;
        div {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 10px;
          background-color: rgba(44, 248, 44, 0.663);
          font-weight: 600;
          border-bottom: 1px solid green;
          &:first-child {
            width: 50px;
            padding: 20px;
          }
          &:last-child {
            justify-content: flex-end;
          }
        }
      }

      .mygroup__table-body--item {
        display: flex;
        position: relative;
        align-items: center;

        .group-name {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          // color: green;
          width: 100%;
          min-width: 170px;
          // max-width: 180px; /* istalgan o'lchamdagi miqdorni o'zgartiring */
          justify-content: space-between;
        }

        .lesson-action {
          display: flex;
        }

        border-bottom: 1px solid green;
        & > div {
          width: 100%;
          padding: 10px;
          border-right: 0;
          p {
            padding: 9px 8px;
          }
          button {
            padding: 10px 15px;
            border-radius: 5px;
            cursor: pointer;
            border: none;
            outline: none;
            &:hover {
              background-color: rgba(44, 248, 44, 0.663);
            }
          }
          .action-body {
            position: absolute;
            right: 0;
            z-index: 10;
            display: none;
            background-color: rgb(225, 223, 223);
            border-radius: 5px;
            button {
              width: 100%;
            }
          }
          &:first-child {
            width: 50px;
          }

          &:last-child {
            width: 80px;
            border-left: 0;
            // border-right: 1px solid green;
          }
        }

        .group-info {
          button {
            padding: 6px 10px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 490px) {
    .mobile {
      .mygroup__table-body--item {
        & > div p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 150px; /* istalgan o'lchamdagi miqdorni o'zgartiring */
          min-width: 60px;
          width: 100%;
        }
      }
    }
  }
  // write to mobile version
}
