h1{
  font-size: 30px;
}

.admin-single__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px;
  cursor: pointer;
  color: black;
}

.admin-single__wrapper{
  margin: 20px 20px;
  padding: 20px;
  border: 1px solid;

  div{
    padding: 10px 0;
  }
}

.ad-teacher{
  
  .ad-teacher-action-body{
    display: none;
    position: absolute;
    border-radius: 5px;
    right: 10px;
    top: 52px;
    background-color: rgb(245, 241, 241);
    z-index: 10;
    *{
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 500px){
    .btn-action{
      margin-right: 10px !important;
    }
    .mygroup__table-head{
      .login{
        display: none !important;
      }
    }
  }
  
}