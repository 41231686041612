.header-section {
  min-height: 58px;
  background-color: #fff;
  position: fixed;
  width: 100%;
  z-index: 9;

  .header__nav {
    max-width: 1320px;
    padding: 50px 0;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    .desktop-menu {
      justify-self: flex-end;
      display: flex;
      position: relative;
      right: 60px;
      gap: 60px;
      li {
        position: relative;
        a {
          font-size: 18px;
          color: black;
          padding: 5px 5px 0;
          &:last-child:hover::after {
            content: " ";
            position: absolute;
            top: 0;
            right: 0px;
            background-color: rgba(0, 128, 0, 0.614);
            opacity: 0.3;
            border-radius: 5px;
            width: 100%;
            height: 120%;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
          }
        }
      }
    }
  }
  .logo__wrapper {
    position: relative;
    .logo__link {
      position: absolute;
    }
    .logo {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: green;
      max-width: 180px; /* istalgan o'lchamdagi miqdorni o'zgartiring */
    }
  }

  .header__logo {
    width: 160px;
    position: absolute;
    top: -80px;
    left: 10px;
  }
  .mobile-menu {
    display: none !important;
    overflow: hidden;
  }

  .logout {
    position: absolute;
    top: 50px;
    right: 0px;
    display: block !important;
  }
}

@media only screen and (max-width: 1080px) {
  .header-section {
    .header__nav .desktop-menu {
      gap: 20px;
    }
  }
}

@media only screen and (max-width: 880px) {
  .header-section {
    .header__nav {
      .desktop-menu {
        li a {
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 805px) {
  .header-section {
    .header__nav {
      padding: 30px 0;
      .desktop-menu {
        li a {
          font-size: 15px;
        }
      }
    }
    .logout {
      top: 30px;
    }
  }
}

@media only screen and (max-width: 935px) {
  .header-section {
    min-height: 58px;
    background-color: #fff;
    position: fixed;
    width: 100%;

    .header__nav {
      position: relative;
      padding-top: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .desktop-menu {
        align-items: center;
        justify-self: flex-end;
        display: flex;
        gap: 20px;
        right: 0;

        li {
          position: relative;
          &:nth-child(6) {
            display: none; /// o'zgardi
          }
          &:nth-child(7) {
            display: none; /// o'zgardi
          }
          &:last-child {
            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-end;
            align-items: center;
            width: 40px;
          }
          justify-content: space-between;
          a {
            font-size: 15px;
            color: black;
            // color: rgb(227, 64, 227);
          }
        }
      }
      .logout {
        top: 128px;
        right: 35px;
        display: none !important;
      }

      .block {
        position: absolute;
        top: 0px;
        left: 0px;
      }

      .mobile-menu {
        display: flex !important;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 1px 1px 1px 1px #999;
        padding: 20px 10px;
        position: absolute;
        top: 80px;
        right: 0;
        gap: 10px;

        // text-align: right;
        li {
          display: none;
          justify-content: flex-end;
          &:nth-child(6) {
            display: flex;
            // justify-content: flex-end;
            // justify-content: space-between;
          }&:nth-child(7) {
            display: flex;
            // justify-content: space-between;
          }
          a {
            font-size: 16px;
            color: black;
            position: relative;
            z-index: 99;
            padding: 5px 5px 0;
            &:hover::after {
              content: " ";
              position: absolute;
              top: 0;
              right: 0px;
              background-color: rgba(0, 128, 0, 0.614);
              opacity: 0.3;
              border-radius: 5px;
              width: 100%;
              height: 120%;
              -webkit-border-radius: 5px;
              -moz-border-radius: 5px;
              -ms-border-radius: 5px;
              -o-border-radius: 5px;
            }
          }
        }
      }
    }
    .logo__wrapper {
      position: relative;
      .logo__link {
        position: absolute;
      }
    }

    .menu-bar {
      display: block !important;
    }
  }
}

@media only screen and (max-width: 900px) {
  .header-section {
    min-height: 58px;
    // padding: 10px 0;
    background-color: #fff;
    position: fixed;
    width: 100%;
    // margin: 50px 0 0 0;

    .header__nav {
      position: relative;

      .logout {
        top: 158px;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      .desktop-menu {
        justify-self: flex-end;
        display: flex;
        gap: 20px;
        li {
          &:nth-child(5) {
            display: none; /// o'zgardi
          }
          a {
            font-size: 15px;
            color: black;
            // color: rgb(227, 64, 227);
          }
        }
      }

      .mobile-menu {
        li {
          &:nth-child(5) {
            display: flex;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 720px) {
  .header-section {
    min-height: 58px;
    // padding: 10px 0;
    background-color: #fff;
    position: fixed;
    width: 100%;
    // margin: 50px 0 0 0;

    .header__nav {
      position: relative;

      .logout {
        top: 188px;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      .desktop-menu {
        justify-self: flex-end;
        display: flex;
        gap: 20px;
        li {
          &:nth-child(4) {
            display: none; /// o'zgardi
          }
          a {
            font-size: 15px;
            color: black;
            // color: rgb(227, 64, 227);
          }
        }
      }
      .mobile-menu {
        li {
          &:nth-child(4) {
            display: flex;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .header-section {
    min-height: 58px;
    background-color: #fff;
    position: fixed;
    width: 100%;

    .header__nav {
      position: relative;

      .logout {
        top: 220px;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      .desktop-menu {
        justify-self: flex-end;
        display: flex;
        gap: 20px;
        li {
          &:nth-child(3) {
            display: none; /// o'zgardi
          }
          a {
            font-size: 15px;
            color: black;
          }
        }
      }
      .mobile-menu {
        li {
          &:nth-child(3) {
            display: flex !important;
          }
        }
      }
    }
  }

  .menu-bar {
    display: flex !important;
  }
}

@media only screen and (max-width: 450px) {
  .header-section {
    min-height: 58px;
    position: fixed;
    width: 100%;

    .header__nav {
      position: relative;

      .logout {
        top: 288px;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      .desktop-menu {
        justify-self: flex-end;
        display: flex;
        gap: 20px;

        .mobile-menu {
          li {
            &:nth-child(1),
            &:nth-child(2) {
              display: none; /// o'zgardi
            }
            a {
              font-size: 15px;
              color: black;
            }
          }
        }
      }
      .mobile-menu {
        li {
          display: flex;
        }
      }

      .teacher {
        li:nth-child(3) {
          display: flex !important;
        }
      }
    }
  }
}

.block {
  display: block !important;
}

.none {
  display: none !important;
}
