* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  transition: all 0.3s ease;
}

a {
  text-decoration: none;
}

/* html {
  scroll-behavior: smooth;
} */

body {
  margin: 0;
  font-family: "Roboto Mono", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1320px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  padding-top: 140px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-without-space {
  display: flex;
  align-items: center;
  gap: 30px;
}

.active {
  border-bottom: solid 1px green;
  color: rgba(0, 128, 0, 0.614);
}

.d-none {
  display: none;
}

.d-block {
  display: block;
}

.error {
  color: rgba(0, 128, 0, 0.614);
  font-size: 17px;
}

.textNotfound{
  color: crimson;
}

.mobile {
  display: none; 
}

/* @media only screen and (max-width: 830px) {
    .tablet {
      display: none;
    }
    .desktop {
      display: none;
    }

    .mobile {
      display: block;

      .mygroup__table-head {
        display: flex;
        div {
          width: 100%;
          padding: 20px;
          border: 2px solid green !important;
          font-weight:600;
          font-size: 19px;
          
          &:first-child {
            border: 2px solid green !important;
            width: 50px;
          }
        }
      }

      .mygroup__table-body--item {
        display: flex;
        
        &>div {
          width: 100%;
          padding: 20px;
          border: 1px solid green;
          border-top: 0;

          border-right:  0;
          display: flex;
          align-items: center;
          &:first-child {
            width: 50px;
          }

          &:last-child {
            width: 80px;
            border-left: 0;
            border-right: 1px solid green;
          }
        }
      }
    }
  } */
