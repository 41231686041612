.login-section {
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    .logo {
      width: 100%;
      max-width: 600px;
    }
    form {
      display: flex;
      width: 100%;
      max-width: 600px;
      gap: 50px;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      .error{
        height: 19px;
      }
      
      &>input {
        width: 100%;
        padding: 15px;
        border: 0.3px solid #999;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        outline: none;
      }
      input.forgot {
        background-color: green;
        color: white;
        cursor: pointer;
      }
      input.forgot:hover {
        border: 0.3px solid green;
        color: green;
        background: white;
      }
    }
  }
}

@media only screen and (max-width: 1365px) {
  .login-section {
    .login {
      .logo {
        width: 100%;
        max-width: 650px;
      }
    }
  }
}

@media only screen and (max-width: 1050px) {
  .login-section {
    .login {
      .logo {
        max-width: 450px;
      }
    }
  }
}
@media only screen and (max-width: 850px) {
  .login-section {
    .login {
      .logo {
        width: 100%;
        max-width: 350px;
      }
    }
  }
}
@media only screen and (max-width: 650px) {
  .login-section {
    .login {
      width: 100%;
      flex-direction: column;
      position: relative;
      padding-top: 200px;
      .logo {
        top: 0;
        position: absolute;
        max-width: 450px;
        z-index: -1;
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .login-section {
    .login {
      width: 100%;
      flex-direction: column;
      position: relative;
      padding-top: 200px;
      .logo {
        top: 60px;
        position: absolute;
        max-width: 450px;
        z-index: -1;
      }
      form {
        gap: 20px;
      }
    }
  }
}
