.admin-group-section{
  .admin-group-section__noBorder{
    input[type="date"] {
      // border-color: black !important;
      // background-color: crimson !important;
      appearance: none !important;
    }
  }
}

.ad-teacher{
  
  .ad-teacher-action-body{
    display: none;
    position: absolute;
    border-radius: 5px;
    right: 10px;
    top: 52px;
    background-color: rgb(245, 241, 241);
    z-index: 10;
    *{
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 500px){
    .btn-action{
      margin-right: 10px !important;
    }
    .mygroup__table-head{
      .login{
        display: none !important;
      }
    }
  }
  
}