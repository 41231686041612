.subject-page {
  ul {
    margin-bottom: 50px;
    li {
      padding: 30px;
      border: 1px solid #1111116c;
      border-radius: 6px;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      -ms-border-radius: 6px;
      -o-border-radius: 6px;
    }
    h2 {
      padding-bottom: 20px;
      font-size: 30px;
    }
    p {
      padding-bottom: 5px;
      font-size: 21px;
    }
  }

  .stats-wrapper {
    display: flex;
    gap: 30px;
    justify-content: center;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 520px) {
  .subject-page {
    ul {
      flex-direction: column;
      gap: 30px;
      li {
        width: 100%;
      }
    }

    .stats-wrapper{
      flex-direction: column;
      gap: 0px;
    }
  }
}
