.mygroup-section {
  table {
    width: 100%;
    border-collapse: collapse;
  }

  .mobile {
    display: none;
  }

  // @media only screen and (max-width: 830px) {
  //   .tablet {
  //     display: none;
  //   }
  //   .desktop {
  //     display: none;
  //   }

  //   .mobile {
  //     display: block;

  //     .mygroup__table-head {
  //       display: flex;
  //       div {
  //         width: 100%;
  //         padding: 20px;
  //         border: 1px solid green;
  //         &:first-child {
  //           width: 50px;
  //         }
  //       }
  //     }

  //     .mygroup__table-body--item {
  //       display: flex;
  //       position: relative;

  //       & > div {
  //         width: 100%;
  //         padding: 20px;
  //         border: 1px solid green;
  //         border-right: 0;
  //         &:first-child {
  //           width: 50px;
  //         }

  //         &:last-child {
  //           width: 80px;
  //           border-left: 0;
  //           border-right: 1px solid green;
  //         }
  //       }
  //     }
  //   }
  // }

  // write to mobile version

  @media only screen and (max-width: 415px){
   .student-group-name a{
    display: inline-block;
    overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: green;
            max-width: 120px; /* istalgan o'lchamdagi miqdorni o'zgartiring */
   } 
  }
}
