.admin-header-section {
  .desktop-menu {
    right: 0 !important;
  }

  .logo__wrapper {
    display: none !important;
  }

  @media only screen and (max-width: 940px) {
    .logo__wrapper {
      display: block !important;
    }
  }
}
