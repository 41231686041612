.password-section{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-self: start;
    .password{
        .password__wrapper{
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 20px;
            h2{
                font-size: 35px;
                margin-bottom: 20px;
            }
            label{
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 5px;
            }
            input{
                width: 400px;
                padding: 15px 10px;
                border: 2px solid #999;
                outline: none;
                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;
                &:focus{
                    border: 2px solid green;
                }
            }
        }
        .password__btn{
            display: flex;
            align-items: center;
            justify-content: space-between;
            a, input{
                padding: 10px 20px;
                border: none;
                outline: none;
                border-radius: 5px;
                background-color: crimson;
                color: white;
                font-size: 16px;
            }

            input{
                background-color: green;
                cursor: pointer;
            }
        }
    }
}