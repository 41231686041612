.pointer-section {
  .pointer {
    color: black;

    a {
      display: inline-block;
      margin-left: 20px;
      margin-bottom: 20px;
      padding: 10px 15px;
      border: 1px solid green;
      color: green !important;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      &:focus, &:hover{
        background-color: green;
        color: white !important;
      }
    }
  }
  .active {
    border: none;
  }
}
