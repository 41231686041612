.mygroup-section {
  table {
    width: 100%;
    border-collapse: collapse;
  }

  .reward__div {
    width: 200px !important;
  }
}
