.profile {
  .info {
    margin-bottom: 50px;
    img {
      width: 221px;
      height: 228px;
    }
    h2 {
      font-size: 30px;
      line-height: 80px;
    }
    & > div {
      width: 100%;
      max-width: 420px;
    }

    .profile-wrapper {
      align-self: flex-end;
      width: 100%;
      max-width: 550px;

      .subinfo {
        display: flex;
        flex-direction: row;
        height: auto !important;
        gap: 100px;
        p {
          margin-bottom: 0;
        }
      }
      h3 {
        margin: 10px 0;
        font-size: 20px;
      }
    }
  }

  .subinfo {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: solid 1px;
    height: 100%;
    height: 228px;
    gap: 20px;
  }
  .contactInfo {
    .subinfo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    strong {
      font-size: 20px;
    }
    p {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
  .auth {
    div {
      border: solid 1px;
      width: 49%;
      // height: 150px;
      padding: 30px 50px;
    }
  }
}

// @media only screen and (max-width: 000px) {
//   .profile {
//     .info {
//       img {
//         width: 150px;
//         height: 150px;
//       }
//       h2 {
//         font-size: 20px;
//         line-height: 50px;
//       }
//     }
//     .subinfo {
//       height: 150px;
//     }
//     .auth {
//       div {
//         padding: 20px 30px;
//       }
//     }
//   }
// }

@media only screen and (max-width: 800px) {
  .profile {
    padding-bottom: 40px;

    .flex {
      display: block !important;
    }

    .info {
      width: 100%;

      img{
        width: 150px;
        height: 150px;
      }

      & > div {
        width: 100%;
        max-width: 100%;
      }

      .profile-wrapper{
        // width: 100% !important;
        max-width: 100% !important;
      }
    }

    .subinfo {
      // padding: 10px;
      display: block !important;

      width: 100% !important;
      height: auto;
      .flex {
        display: flex !important;
      }
    }

    .userinfo {
      width: 100%;
    }

    .auth {
      * {
        width: 100% !important;
      }
    }

    .login {
      margin-bottom: 30px;
    }
  }
}
